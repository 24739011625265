// Model to use in lisitng model. Readind listings become more efficient

export class UserListingData {
    firstName: string;
    lastName: string;
    photoUrl: string;
    userId: string;

    constructor() {
        this.firstName = '';
        this.lastName = '';
        this.photoUrl = '';
        this.userId = '';
    }
}