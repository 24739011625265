import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Facebook} from "@ionic-native/facebook/ngx";
import {User} from "../model/User";
import {AngularFirestore} from "@angular/fire/firestore";
import {Listing} from "../model/Listing";
import {BehaviorSubject, Observable} from 'rxjs';
import {UserService} from './user.service';
import {take} from 'rxjs/operators';
import {SettledListing} from '../model/SettledListing';



@Injectable({
  providedIn: 'root'
})
export class ListingService {

  public user: User;
  public listings$: BehaviorSubject<Array<Listing>> = new BehaviorSubject<Array<Listing>>([]);


  constructor(
    public platform: Platform,
    public facebook: Facebook,
    private firestore: AngularFirestore,
    private userService: UserService) {
  }


  public locationQuery(city?: string, country?: string) {
    if (city) {
      this.searchByCityAndCountry(city, country);
    } else {
      this.searchByCountry(country);
    }
  }

  private searchByCityAndCountry(city?: string, country?: string) {
    this.firestore.firestore.collection('listings')
      .where("location.country", "==", country)
      .where("location.city", "==", city)
      .orderBy("startDate", "asc").onSnapshot(querySnapshot => {
        this.processSearchResults(querySnapshot);
      });
  }

  private searchByCountry(country?: string) {
    this.firestore.firestore.collection('listings')
      .where("location.country", "==", country)
      .orderBy("startDate", "asc").onSnapshot(querySnapshot => {
        this.processSearchResults(querySnapshot);
      });
  }

  private processSearchResults(querySnapshot) {

    const list: Listing[] = [];
    if(!querySnapshot.docs.length) {
      this.listings$.next(list);
    } else {
      querySnapshot.docs.forEach(doc => {
        let listing = doc.data() as Listing;
        list.push(listing);
        if(list.length === querySnapshot.docs.length) {
          this.listings$.next(list);
        }
      });
    }
  }

  public listAndObserve(city?: string, country?: string): Observable<Listing[]> {
    console.log('Looking for city', city);
    console.log('And country', country);
      this.locationQuery(city, country);
      return this.listings$;
  }


  public getFilteredList(city?: string, country?: string): Promise<any> {
      let list: Listing[] = [];
      return this.firestore.firestore.collection('listings')
          .where("location.country", "==", country)
          .where("location.city", "==", city)
          .get()
          .then((querySnapshot) => {
              querySnapshot.forEach(doc => {
                  let listing = new Listing();

                  listing = doc.data() as Listing;
                  list.push(listing);
              });
              return list;
          });
  }


  getListing(uid) {
    return new Promise((resolve, reject) => {
      this.firestore.collection('listings').doc(uid).get().pipe(take(1)).subscribe(response => {
        resolve(response.data());
      });
    });
  }


  private prepareData(listing: Listing) {
    const objectLocation = Object.assign({}, listing.location);
    const objectUserData = Object.assign({}, listing.user);
    listing.location = objectLocation;
    listing.user = objectUserData;
  }


  save(listing: Listing): Promise<any> {
    listing.user.userId = this.userService.user.uid;
    this.prepareData(listing);
    if (listing.key) {
      return this.update(listing);
    } else {
      return this.firestore.collection('listings').add({...listing}).then(result => {
        listing.key = result.id;
        this.update(listing);
      });
    }
  }


  private update(listing) {
    this.prepareData(listing);
    return this.firestore.collection('listings').doc(listing.key).set({...listing}, {merge: true}).then(result => {
    });
  }


  deleteListing(listingId: string): Promise<any> {
      return this.firestore.firestore.collection('listings').doc(listingId).delete();
  }


  saveSettledListing(settledListing: SettledListing): Promise<any> {
      return this.firestore.firestore.collection('SettledListings').add({...settledListing})
          .then(docRef => {
              settledListing.settledListingKey = docRef.id;
              this.updateSettledListing(settledListing);
          });
  }


  updateSettledListing(settledListing: SettledListing): Promise<any> {
      return this.firestore.firestore.collection('SettledListings')
          .doc(settledListing.settledListingKey)
          .update({ settledListingKey: settledListing.settledListingKey });
  }

}
