import {User} from "./User";
import {firestore} from 'firebase/app';
import Timestamp = firestore.Timestamp;
import {Location} from "./location";
import {UserListingData} from './UserListingData';

export class Listing {
  key?: string;
  url?: string;
  description?: string;
  image?: string;
  startDate?: Timestamp;
  endDate?: Timestamp;
  price: number;
  location: Location;
  user: UserListingData;
  spacesAvailable?: number;

  constructor() {
    this.key = '';
    this.url = '';
    this.description = '';
    this.image = '';
    this.price = 0;
    this.startDate = null;
    this.endDate = null;
    this.location = new Location();
    this.spacesAvailable = 0;
    this.user = new UserListingData();
  }
}
