
export class Location {
    country: string;
    city: string;
    full: string;

    constructor() {
        this.country = '';
        this.city = '';
        this.full = '';
    }
}
